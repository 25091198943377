import React, { useState, useEffect } from 'react'
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import Text from '../components/text'
import PortableText from "@sanity/block-content-to-react"
import serializer from "../utils/serializer"
import Time from "../utils/time"
import ReactMoment from 'react-moment'
import 'moment-timezone'
import moment from 'moment'
import styled from 'styled-components'  
import sanitizeHtml from 'sanitize-html'
import { Helmet } from "react-helmet"

import { 
  type_body, 
  type_body_bold,
  baseColor, 
  breakpoint, 
  blackColor, 
  greyColor, 
  typeBody, 
  typeDate, 
  typeCloseBtn, 
  grid, 
  margin,
  colorTransition, 
  blurTransition, 
  TextWrap, 
  headerHeight,
  headerHeightMobile,
  footerHeight,
  footerHeightMobile
} from '../styles/helpers'
import { 
  PageWrap,
  PageWrapInner,
  PageNav,
  PageNavItem,
  PageEntry
} from '../styles/page'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
moment.tz.setDefault('Europe/Amsterdam')


export const query = graphql`
  query($slug: String) {
    sanityArchive(slug: { current: { eq: $slug } }) {
      title
      id
      slug {
        _key
        _type
        current
      }
      pageDate
      _rawText
    }
  }
`

export default ({ data, location, ...props }) => {
  
  // const seoComponent = !seoImage ? <SEO title={data.sanityEvent.title} description={seoDescription} /> : <SEO title={data.sanityEvent.title} description={seoDescription} image={seoImage} />
  const body = data.sanityArchive._rawText ? <Text info={false} data={data.sanityArchive._rawText} background={false} /> : false


  return (
    <>
    {/* {seoComponent} */}
     <React.Fragment>
      <PageWrap id = 'archive'>
        <PageWrapInner columnWidth={8}>
          <PageEntry noPadding={true}>
            {body}
          </PageEntry>
        </PageWrapInner>
      </PageWrap>
    </React.Fragment>
    </>
  )
}
